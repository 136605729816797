<template>
  <div class="upload-page">
    <div class="intro">
      欢迎来到FIN自动评估平台！提交后，每个类别和子类别的准确性细分将显示在下面。JSON文件也可以下载。
    </div>
    <div class="rules">
      <p class="title"><b>Submission Format</b></p>
      <ul>
        <li>
          Here is a
          <a
            id="download_sample"
            href="https://file.huatuogpt.cn/files/models_ref/cmb/sample.json"
            >sample</a
          >
          for submission.
        </li>
        <li>
          You can validate the format by running the following Python code
          snippet:<br />
          <pre>
import json
samples = json.load(open(fp))


assert type(samples) == list
assert len(samples) == 11200
assert all([type(s) == dict for s in samples])


# "id" and "model_answer" are required keys for each sample.
Redundant keys have no effect on evaluation.
assert all(['id' in s for s in samples]), "'id' must be a key of
every sample"
assert all(['model_answer' in s for s in samples]), "'model_answer'
must be a key of every sample"
assert sorted([s['id'] for s in samples]) == list(range(1,
11200+1)), 'ids must start from 1 and end at 11200'


print('good to go!')
          </pre>
        </li>
      </ul>
    </div>

    <!-- 上传 -->
    <div class="upload-box d-flex">
      <el-upload
        class="upload-demo"
        ref="upload"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-preview="handlePreview"
        :on-remove="handleRemove"
        :file-list="fileList"
        :auto-upload="false"
        accept=".json"
        :limit="1"
      >
        <el-button slot="trigger" size="small" type="primary"
          >Choose File</el-button
        >
        <el-button
          style="margin-left: 12px"
          size="small"
          type="success"
          @click="submitUpload"
          >Upload & Process</el-button
        >
      </el-upload>
    </div>
    <div class="rules">
      <b>Submit</b> <br /><br />
      1.Choose a local <code>.json</code> file by clicking the
      <b>"Choose File"</b> button. <br /><br />
      2.Click the <b>"Upload &amp; Process"</b> button, It usually takes less
      than one minute to score the answers. Once processed, results will be
      displayed in the <b>"Result"</b> section below. <br /><br />
      3.Send result score to xxxxx for publicity
      <br />
    </div>

    <div class="result">
      <h2>Result</h2>
      <div class="rules"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    submitUpload() {
        console.log('upload')
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);

    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload-page {
  margin-top: 24px;
  width: 100%;
  min-height: 900px;
  .intro {
    margin-bottom: 32px;
    text-align: left;
  }
  .rules {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 20px;
    text-align: left;
    font-family: monospace;
    ul {
      padding-left: 20px;
    }
  }
  .upload-box {
    margin-bottom: 24px;
    .upload-demo {
      display: flex;
      justify-content: start;
      align-items: center;
    }
  }
  .result {
    text-align: left;
    h2 {
        margin-bottom: 10px;
    }
  }
}
</style>