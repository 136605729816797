<template>
  <div class="cmb-page">
    <div class="cmb-page_header container">
      <div class="left">
        <div class="title">FIN Benchmark</div>
        <div class="desc">“通用·信任”金融大模型评测系统 FIN Benchmark</div>
        <div class="link">XX大学 | xxx公司 | xxx实验室 合作共建</div>
      </div>
      <div class="right">
        <img class="img" src="@/assets/images/fin/header_icon.png" fit="fill" />
      </div>
    </div>

    <!-- tabs -->
    <div class="cmb-page_content container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane name="1">
          <span slot="label"><i class="el-icon-notebook-1"></i> 关于FIN</span>
          <FINAbout></FINAbout>
        </el-tab-pane>
        <el-tab-pane name="2">
          <span slot="label"><i class="el-icon-data-line"></i> 排行榜单</span>
          <FINLeaderBoard></FINLeaderBoard>
        </el-tab-pane>
        <el-tab-pane name="3">
          <span slot="label"><i class="el-icon-folder-add"></i> 上传模型</span>
          <FINUpload></FINUpload>
        </el-tab-pane>
        <el-tab-pane name="4" disabled>
          <div slot="label" class="" @click="jumpPaper()">
            <span><i class="el-icon-document"></i> 论文报告</span>
          </div>
        </el-tab-pane>
        <el-tab-pane name="5" disabled>
          <div slot="label" class="" @click="jumpGitHub()">
            <span slot="label"><i class="el-icon-link"></i> GitHub</span>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import FINAbout from "@/components/fin/About";
import FINLeaderBoard from "@/components/fin/LeaderBoard";
import FINUpload from "@/components/fin/Upload";

export default {
  components: { FINAbout, FINLeaderBoard, FINUpload },
  data() {
    return {
      activeName: "1",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    jumpPaper() {
      window.open("https://arxiv.org/abs/2308.08833");
    },
    jumpGitHub() {
      window.open(" https://github.com/FreedomIntelligence/FIN");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item.is-disabled {
  color: #303133;
  cursor: pointer;
}
.cmb-page {
  .cmb-page_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 400px;
    padding-top: 60px;
    background-color: #b19a58;
    .left {
      text-align: left;
      .title {
        height: 64px;
        font-weight: 500;
        font-size: 56px;
        color: #ffffff;
        line-height: 64px;
        margin-bottom: 29px;
      }
      .desc {
        height: 32px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 24px;
        color: #ffffff;
        margin-bottom: 18px;
      }
      .link {
        width: fit-content;
        padding: 7px 24px;
        background: linear-gradient(90deg, #d4ae40 0%, #dcbc60 100%);
        border-radius: 31px;
        color: #fff;
      }
    }
    .right {
      .img {
        width: 275.8px;
        height: 250.22px;
      }
    }
  }

  .cmb-page_content {
    margin-top: 16px;
  }
}
</style>